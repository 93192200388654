<template>
	<div class="detailed">
		<pagehead :headText="headText"></pagehead>
		<div class="detailed-box">
			<div class="detailed-tips">{{ row.noticeTitle }}</div>
			<div class="detai-time">
				<div class="time-text">
					<p class="iconfont icon-shizhongfill"></p>
					<p>{{ row.publishTime }}</p>
					<p>{{ row.remark }}</p>
				</div>
				<!-- <div class="share">
					<p></p>
					<p></p>
				</div> -->
			</div>
			<div class="new-contnet" v-html="row.noticeContent"></div>
			<div v-if="PreviousObj" class="turnpages" @click="PreviousClick">
				<span>上一篇：</span>
				<span>{{ PreviousObj.noticeTitle }}</span>
			</div>
			<div v-if="NextObj" class="turnpages turnpages-padding-bottom" @click="NextClick">
				<span>下一篇：</span>
				<span>{{ NextObj.noticeTitle }}</span>
			</div>
		</div>
	</div>
</template>
<script>
import { listInfoByInfoName, listInfoByInfoId, pageListInfoByColumnName, pageListInfoByColumnId } from '@/api/api.js'

import pagehead from '@/components/Pagehead'
export default {
	data() {
		return {
			headText: '详情',
			row: {},
			total: 0,
			pageSize: 2,
			pageNum: 1,
			newlist: [],
			PreviousObj: {}, //上一篇内容
			NextObj: {}, //下一篇内容
			rowLen: 0
		}
	},
	props: {},
	components: {
		pagehead
	},

	computed: {
		routerVla() {
			return this.$route.query.id
		}
	},
	watch: {
		routerVla() {
			this.Getinfo(this.$route.query.id)
		}
	},
	mounted() {
		this.Getinfo(this.$route.query.id)
	},

	methods: {
		PreviousClick() {
			//上一篇
			this.row = this.PreviousObj
			this.rowLen = this.rowLen - 1
			if (this.rowLen > 0) {
				this.PreviousObj = this.newlist[this.rowLen - 1]
				this.NextObj = this.newlist[this.rowLen + 1]
			} else {
				this.PreviousObj = ''
				this.NextObj = this.newlist[this.rowLen + 1]
				return
			}
		},
		NextClick() {
			//下一篇
			this.row = this.NextObj
			this.rowLen = this.rowLen + 1
			if (this.rowLen < this.total) {
				this.PreviousObj = this.newlist[this.rowLen - 1]
				this.NextObj = this.newlist[this.rowLen + 1]
			} else {
				this.PreviousObj = ''
				this.NextObj = this.newlist[this.rowLen - 1]
				return
			}
		},
		Getcolumnitem() {
			let params = {
				pageSize: 1000,
				pageNum: 1,
				//columnName: this.row.sysColumn.columnName
				columnId: this.row.sysColumn.columnId
			}
			//pageListInfoByColumnName(params)
			pageListInfoByColumnId(params)
				.then(res => {
					this.newlist = res.rows
					this.total = res.total
					for (let i = 0; i < res.rows.length; i++) {
						if (this.row.noticeId == res.rows[i].noticeId) {
							this.rowLen = i
							if (i != 0 && i < res.rows.length - 1) {
								this.PreviousObj = res.rows[i - 1]
								this.NextObj = res.rows[i + 1]
							}
							if (i == 0) {
								this.PreviousObj = ''
								this.NextObj = res.rows[i + 1]
							}
							if (i == res.rows.length - 1) {
								this.PreviousObj = res.rows[i - 1]
								this.NextObj = ''
							}
						}
					}
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		},

		Getinfo(id) {
			//获取详情
			let params = {
				id: id
			}
			listInfoByInfoId(params)
				.then(res => {
					this.row = res
					this.Getcolumnitem()
				})
				.catch(error => {
					this.$message.error('获取数据失败！')
				})
		}
	}
}
</script>
<style scoped lang="scss">
.detailed {
	width: 100%;

	.detailed-box {
		width: 1330px;
		padding-top: 85px;
		margin: 0 auto;
		padding-bottom: 129px;
	}
	.detailed-tips {
		font-size: 20px;
		font-family: Source Han Sans CN;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
		text-align: left;
	}
	.detai-time {
		padding-top: 35px;
		display: flex;
		justify-content: space-between;
		div {
			display: flex;
			flex-direction: row;
		}
		.time-text {
			p:nth-child(1) {
				color: #288183;
				font-size: 16px;
				padding-right: 14px;
				line-height: 16px;
			}
			p:nth-child(2) {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #959595;
				line-height: 16px;
				padding-right: 61px;
			}
			p:nth-child(3) {
				font-size: 16px;
				font-family: Source Han Sans CN;
				font-weight: 400;
				color: #959595;
				line-height: 16px;
			}
		}
		.share {
			p {
				width: 32px;
				height: 26px;
				margin-left: 50px;
			}
			p:nth-child(1) {
				background: url(../../static/images/weibo.png) no-repeat center;
				background-size: 100% 100%;
			}
			p:nth-child(2) {
				background: url(../../static/images/weix.png) no-repeat center;
				background-size: 100% 100%;
			}
		}
	}
	.new-contnet {
		padding: 60px 0px 92px;
	}
	.turnpages {
		display: flex;
		flex-direction: row;
		padding-top: 21px;
		cursor: pointer;
		span {
			font-size: 16px;
			font-family: MicrosoftYaHei;
			font-weight: 400;
			line-height: 30px;
			text-align: left;
		}
		span:nth-child(1) {
			color: #959595;
		}
		span:nth-child(2) {
			color: #333333;
		}
	}
	.turnpages-padding-bottom {
	}
}
</style>
